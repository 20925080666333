<template>
	<el-dialog title="历年数据" :visible.sync="historyShow" :before-close="close" class="choiceMajor" width="60%">
		<el-table ref="mainTable" :data="list" border style="width: 100%" :row-style="{ height: '50px' }"
			v-loading="listLoading" :header-row-style="{ cursor: 'pointer' }" height="500">
			<el-table-column min-width="80px" label="年份" fixed>
				<template slot="header">
					<el-popover placement="bottom" width="295" trigger="click">
						<div class="selectBox">
							<AuthSelect v-model="listQuery.syS_Years" :data-source="'SYS_Years'"></AuthSelect>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">年份<i class="el-icon-arrow-down"></i></span>
					</el-popover>
					<!-- <el-link :underline="false" type="primary" slot="reference">{{scope.row.name}}</el-link> -->
				</template>
				<template slot-scope="scope">
					<span>{{ getGategoryName(scope.row.syS_Years, "SYS_Years") }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="schoolName" label="院校名称" width="150"></el-table-column>
			<el-table-column prop="schoolCode" label="院校代码" width="100"></el-table-column>
			<el-table-column label="文理科">
				<template slot="header">
					<el-popover placement="bottom" width="295" trigger="click">
						<div class="selectBox">
							<el-select v-model="listQuery.syS_SubjectGroup" placeholder="请选择" size="mini">
								<el-option v-for="item in SubjectGroup" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">文理科<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
				<template slot-scope="scope">
					<span>{{ getGategoryName(scope.row.syS_SubjectGroup, "SYS_SubjectGroup")}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="syS_Times" label="批次" width="150">
				<template slot="header">
					<el-popover placement="bottom" width="295" trigger="click">
						<div class="selectBox">
							<!-- <el-select v-model="listQuery.syS_Times" placeholder="请选择" size="mini">
								<el-option v-for="item in syS_Times" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select> -->
							<AuthSelect :data-source="'SYS_Times'" v-model="listQuery.syS_Times" size="mini">
							</AuthSelect>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">批次<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
				<template slot-scope="scope">
					<span>{{ getGategoryName(scope.row.syS_Times, "SYS_Times") }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="scores" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input placeholder="最小值" v-model="listQuery.minScores" size="mini" style="width: 70px">
							</el-input>&nbsp;-
							<el-input placeholder="最大值" v-model="listQuery.maxScores" size="mini" style="width: 70px">
							</el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">分数详情<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="plans" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input placeholder="最小值" v-model="listQuery.minPlans" size="mini" style="width: 70px">
							</el-input>&nbsp;-
							<el-input placeholder="最大值" v-model="listQuery.maxPlans" size="mini" style="width: 70px">
							</el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">计划数<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="posts" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input placeholder="最小值" v-model="listQuery.minPosts" size="mini" style="width: 70px">
							</el-input>&nbsp;-
							<el-input placeholder="最大值" v-model="listQuery.maxPosts" size="mini" style="width: 70px">
							</el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">投档数<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="receives" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input placeholder="最小值" v-model="listQuery.minReceives" size="mini" style="width: 70px">
							</el-input>&nbsp;-
							<el-input placeholder="最大值" v-model="listQuery.maxReceives" size="mini" style="width: 70px">
							</el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">录取数<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="minscore" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input v-model="listQuery.minscore" size="mini"></el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">最低分<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="avescore" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input v-model="listQuery.avescore" size="mini"></el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">平均分<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="scoreline" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input v-model="listQuery.scoreline" size="mini"></el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">分数线<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="minscoredif" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input v-model="listQuery.minscoredif" size="mini" style="width: 70px">
							</el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">最低分线差<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="avescoredif" width="110">
				<template slot="header">
					<el-popover placement="bottom" width="250" trigger="click">
						<div class="selectBox">
							<el-input v-model="listQuery.avescoredif" size="mini" style="width: 70px">
							</el-input>
							<el-button size="mini" style="margin-left: 10px" @click="getList()">确认</el-button>
						</div>
						<span slot="reference">平均分线差<i class="el-icon-arrow-down"></i></span>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="minplace" label="最低分名次" show-overflow-tooltip></el-table-column>
			<el-table-column prop="scorelineplace" label="分数线名次" show-overflow-tooltip></el-table-column>
			<el-table-column prop="aveplace" label="平均分名次" show-overflow-tooltip></el-table-column>
			<el-table-column prop="plansadd" label="计划数增加" show-overflow-tooltip></el-table-column>
		</el-table>
		<Pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
			@pagination="handleCurrentChange" />
	</el-dialog>
</template>

<script>
	import * as schoolSubjectGroups from "@/api/schoolsubjectgroups";
	import * as categorys from "@/api/categorys";
	import Pagination from "@/components/Pagination";
	import AuthSelect from "@/components/AuthSelect";
	export default {
		components: {
			Pagination,
			AuthSelect
		},
		data() {
			return {
				listLoading: false,
				total: 0,
				show: false,
				gategoryList: [],
				list: null,
				SubjectGroup: [{
						value: "1",
						label: "文科",
					},
					{
						value: "2",
						label: "理科",
					},
				],
				syS_Times: [{
						value: "16",
						label: "高职高专",
					},
					{
						value: "12",
						label: "本科第二批",
					},
				],
				listQuery: {
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					schoolName: "", //学校名称
					syS_Years: "", // 年份
					schoolCode: "", // 学校编号
					syS_SubjectGroup: "", // 文理科
					syS_CreateType: "", //院校类别
					syS_Times: "", // 批次
					minPlans: "", // 计划数
					maxPlans: "", // 计划数
					minPosts: "", // 投档数
					maxPosts: "", // 投档数
					minReceives: "", // 录取数
					maxReceives: "", // 录取数
					minScores: "", // 分数详情
					maxScores: "", // 分数详情
					minscore: "", // 最低分
					avescore: "", // 平均分
					scoreline: "", // 分数线
					minscoredif: "", // 最低分线差
					avescoredif: "", // 平均分线差
					minplace: "", // 最低分名次
					scorelineplace: "", // 分数线名次
					aveplace: "", // 平均分名次
					plansadd: "", // 计划数增加
					schoolRanking: "", // SchoolRanking
					minplace1: "", // Minplace1
					minplace2: "", // Minplace2
					minplace3: "", // Minplace3
					aveplace1: "", // Aveplace1
					aveplace2: "", // Aveplace2
					aveplace3: "", // Aveplace3
					syS_AdmissoinRule: "", // SYS_AdmissoinRule
					plansPostsReceives1: "", // PlansPostsReceives1
					plansPostsReceives2: "", // PlansPostsReceives2
					plansPostsReceives3: "", // PlansPostsReceives3
					scoreline1: "", // Scoreline1
					scoreline2: "", // Scoreline2
					scoreline3: "", // Scoreline3
					minScore1: "", // MinScore1
					minScore2: "", // MinScore2
					minScore3: "", // MinScore3
					aveScore1: "", // AveScore1
					aveScore2: "", // AveScore2
					aveScore3: "", // AveScore3
					select: false,
					cityName: "", //城市
					diC_KeySchool: [], //院校层次
					province: "", //省份
					MobileTel: ""
				},
			};
		},
		props: {
			historyShow: Boolean,
			school: Object,
		},
		methods: {
			handleCurrentChange(val) {
				this.listQuery.page = val.page;
				this.listQuery.limit = val.limit;
				this.getList();
			},
			close() {
				this.$emit("closeHistory", false);
			},
			getList() {
				this.list = [];
				this.listLoading = true;
				//console.log(this.choiceSchool);
				this.listQuery.schoolCode = this.school.schoolCode;
				this.listQuery.syS_Years="last3";
				this.listQuery.syS_SubjectGroup = this.school.syS_SubjectGroup;
				console.log(this.listQuery);
				schoolSubjectGroups.getLoad(this.listQuery).then((response) => {
					let list1 = response.data;
					//console.log(list1);
					for (let i = 0; i < list1.length; i++) {
						var o = list1[i];
						this.list.push(o);
					}
					//this.listLoading = true
					//schoolSubjectGroups.getList(this.listQuery).then(response => {
					//  this.list = response.data
					this.total = response.count;
					this.listLoading = false;
					//console.log(this.list);
				});
			},
			getGategorys() {
				let listQuery = {
					page: 1,
					limit: 9999,
				};
				//console.log('getcategorys')
				categorys.getList(listQuery).then((res) => {
					this.gategoryList = res.data;
					//console.log(this.gategoryList);
					//for(let key in this.gategoryList){
					//    console.log(this.gategoryList[key])
					//}
					//console.log('getcategorys3')
				});
			},
			getGategoryName(val, columnName) {
				var dicobject = this.gategoryList.find(
					(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
				);
				//var dicobject = this.gategoryList.find(t => {
				//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
				//    return t
				//  }
				//});

				if (!dicobject) {
					return "";
				} else {
					return dicobject.name;
				}
			},
		},
		watch: {
			historyShow(val) {
				if (val) {
					this.getGategorys();
					this.getList();
				}
			}
		},
	};
</script>

<style lang="scss">
	.choiceMajor {
		.top {
			display: flex;
			text-align: right;
			height: 40px;
			line-height: 40px;
			margin-bottom: 10px;

			.item {
				padding: 0 15px;
			}

			.el-input {
				width: 50%;
			}

			.majorText {
				flex: 1;

				.majorNum {
					padding: 0 5px;
					color: red;
				}
			}
		}
	}

	.el-popover {
		.selectBox {
			display: flex;
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eee !important;
	}

	.el-dialog__body {
		padding: 20px !important;
	}

	.el-dialog .el-table .cell {
		white-space: nowrap !important;
	}
</style>
